/**
 * Transaction process graph for bookings:
 *   - default-offsession
 */

/**
 * Transitions
 *
 * These strings must sync with values defined in Marketplace API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

export const transitions = {
    REQUEST_BOOKING: 'transition/request-booking',
    INQUIRE: 'transition/inquire',
    REQUEST_BOOKING_AFTER_INQUIRE: 'transition/request-booking-after-inquiry',
    ACCEPT: 'transition/accept',
    OPERATOR_ACCEPT: 'transition/operator-accept',
    DECLINE: 'transition/decline',
    EXPIRE: 'transition/expire',
    MARK_PENDING_PAYMENT: 'transition/mark-pending-payment',
    CANCEL_BY_CUSTOMER_AFTER_ACCEPTED_BEFORE_48HR:'transition/cancel-by-customer-after-accepted-before-48hr',
    CANCEL_BY_PROVIDER_AFTER_ACCEPTED :'transition/cancel-by-provider-after-accepted',
    CANCEL_BY_CUSTOMER_AFTER_ACCEPTED:'transition/cancel-by-customer-after-accepted',
    CANCEL_BY_OPERATOR_AFTER_PENDINGPAYMENT_BEFORE_48HR:'transition/cancel-by-customer-after-pendingpayment-before-48hr',
    CANCEL_BY_PROVIDER_AFTER_PENDINGPAYMENT :'transition/cancel-by-provider-after-accepted-pendingpayment',
    CANCEL_BY_CUSTOMER_AFTER_PENDINGPAYMENT:'transition/cancel-by-operator-after-pendingpayment',
    INITIATE_MANUAL_PAYMENT: 'transition/initiate-manual-payment',
    AUTO_PAYMENT: 'transition/auto-payment',
    CONFIRM_MANUAL_PAYMENT: 'transition/confirm-manual-payment',
    CANCEL_PENDING_CONFIRMATION: 'transition/cancel-from-pending-confirmation',
    CANCEL_BY_CUSTOMER_BEFORE_ACTION: "transition/cancel-by-customer-before-action",
    CANCEL_BY_CUSTOMER_AFTER_ACCEPT: "transition/cancel-by-customer-before-48hr",
    CANCEL_BY_PROVIDER: "transition/cancel-by-provider",
    COMPLETE: 'transition/complete',
    OPERATOR_COMPLETE: 'transition/operator-complete',
    CANCEL: 'transition/cancel',
    REVIEW_1_BY_PROVIDER: 'transition/review-1-by-provider',
    REVIEW_2_BY_PROVIDER: 'transition/review-2-by-provider',
    REVIEW_1_BY_CUSTOMER: 'transition/review-1-by-customer',
    REVIEW_2_BY_CUSTOMER: 'transition/review-2-by-customer',
    EXPIRE_CUSTOMER_REVIEW_PERIOD: 'transition/expire-customer-review-period',
    EXPIRE_PROVIDER_REVIEW_PERIOD: 'transition/expire-provider-review-period',
    EXPIRE_REVIEW_PERIOD: 'transition/expire-review-period',

};

export const states = {
    INITIAL: 'initial',
    INQUIRY: 'inquiry',
    PENDING: 'pending',
    ACCEPTED: 'accepted',
    DECLINED: 'declined',
    PENDING_PAYMENT: 'pending-payment',
    PENDING_CONFIRMATION: 'pending-confirmation',
    PAID: 'paid',
    CANCELLED: 'cancelled',
    DELIVERED: 'delivered',
    REVIEWED: 'reviewed',
    REVIEWED_BY_CUSTOMER: 'reviewed-by-customer',
    REVIEWED_BY_PROVIDER: 'reviewed-by-provider',
};

export const graph = {
    id: 'default-offsession/release-1',
    initial: states.INITIAL,

    states: {
        [states.INITIAL]: {
            on: {
                [transitions.INQUIRE]: states.INQUIRY,
                [transitions.REQUEST_BOOKING]: states.PENDING,
            }
        },
        [states.INQUIRY]: {
            on: {
                [transitions.REQUEST_BOOKING_AFTER_INQUIRE]: states.PENDING
            }
        },
        [states.PENDING]: {
            on: {
                [transitions.ACCEPT]: states.ACCEPTED,
                [transitions.DECLINE]: states.DECLINED,
                [transitions.EXPIRE]: states.DECLINED,
                [transitions.CANCEL_BY_CUSTOMER_BEFORE_ACTION]: states.DECLINED
            }
        },
        [states.ACCEPTED]: {
            on: {
                [transitions.MARK_PENDING_PAYMENT]: states.PENDING_PAYMENT, 
                [transitions.CANCEL_BY_CUSTOMER_AFTER_ACCEPTED_BEFORE_48HR]: states.CANCELLED,
                [transitions.CANCEL_BY_PROVIDER_AFTER_ACCEPTED]: states.CANCELLED,
                [transitions.CANCEL_BY_CUSTOMER_AFTER_ACCEPTED_BEFORE_48HR]: states.CANCELLED, 
            }
        },
        [states.DECLINED]: {},
        [states.PENDING_PAYMENT]: {
            on: {
                [transitions.AUTO_PAYMENT]: states.PAID,
                [transitions.INITIATE_MANUAL_PAYMENT]: states.PENDING_CONFIRMATION,
                [transitions.CANCEL_BY_OPERATOR_AFTER_PENDINGPAYMENT_BEFORE_48HR]: states.CANCELLED,
                [transitions.CANCEL_BY_PROVIDER_AFTER_PENDINGPAYMENT]: states.CANCELLED,
                [transitions.CANCEL_BY_CUSTOMER_AFTER_PENDINGPAYMENT]: states.CANCELLED,
            }
        },
        [states.PENDING_CONFIRMATION]: {
            on: {
                [transitions.CONFIRM_MANUAL_PAYMENT]: states.PAID,
                [transitions.CANCEL_PENDING_CONFIRMATION]: states.CANCELLED
            }
        },
        [states.PAID]: {
            on: {
                [transitions.COMPLETE]: states.DELIVERED,
                [transitions.OPERATOR_COMPLETE]: states.DELIVERED,
                [transitions.CANCEL]: states.CANCELLED,
                [transitions.CANCEL_BY_CUSTOMER_AFTER_ACCEPT]: states.CANCELLED,
                [transitions.CANCEL_BY_PROVIDER]: states.CANCELLED
            }
        },
        [states.CANCELLED]: {},
        [states.DELIVERED]: {
            on: {
                [transitions.REVIEW_1_BY_PROVIDER]: states.REVIEWED_BY_PROVIDER,
                [transitions.REVIEW_1_BY_CUSTOMER]: states.REVIEWED_BY_CUSTOMER,
                [transitions.EXPIRE_REVIEW_PERIOD]: states.REVIEWED
            }
        },
        [states.REVIEWED_BY_PROVIDER]: {
            on: {
                [transitions.REVIEW_2_BY_CUSTOMER]: states.REVIEWED,
                [transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD]: states.REVIEWED
            }
        },
        [states.REVIEWED_BY_CUSTOMER]: {
            on: {
                [transitions.REVIEW_2_BY_PROVIDER]: states.REVIEWED,
                [transitions.EXPIRE_PROVIDER_REVIEW_PERIOD]: states.REVIEWED
            }
        },
        [states.REVIEWED]: { type: 'final' }
    }
};

export const isRelevantPastTransition = transition =>{
    return [
        transitions.ACCEPT,
        transitions.DECLINE,
        transitions.EXPIRE,
        transitions.MARK_PENDING_PAYMENT,
        transitions.AUTO_PAYMENT,
        transitions.INITIATE_MANUAL_PAYMENT,
        transitions.CONFIRM_MANUAL_PAYMENT,
        transitions.CANCEL_PENDING_CONFIRMATION,
        transitions.COMPLETE,
        transitions.CANCEL,
        transitions.CANCEL_BY_CUSTOMER_BEFORE_ACTION,
        transitions.CANCEL_BY_CUSTOMER_AFTER_ACCEPT,
        transitions.CANCEL_BY_PROVIDER,
        transitions.REVIEW_1_BY_CUSTOMER,
        transitions.REVIEW_1_BY_PROVIDER,
        transitions.REVIEW_2_BY_CUSTOMER,
        transitions.REVIEW_2_BY_PROVIDER,
        transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD,
        transitions.EXPIRE_PROVIDER_REVIEW_PERIOD,
        transitions.EXPIRE_REVIEW_PERIOD,
        transitions.CANCEL_BY_CUSTOMER_AFTER_ACCEPTED_BEFORE_48HR,
        transitions.CANCEL_BY_PROVIDER_AFTER_ACCEPTED
    ].includes(transition);
};

export const isCustomerReview = transition => {
    return [transitions.REVIEW_1_BY_CUSTOMER, transitions.REVIEW_2_BY_CUSTOMER].includes(transition);
};

export const isProviderReview = transition => {
    return [transitions.REVIEW_1_BY_PROVIDER, transitions.REVIEW_2_BY_PROVIDER].includes(transition);
};

export const isPrivileged = transition => {
    return [transitions.REQUEST_BOOKING, transitions.REQUEST_BOOKING_AFTER_INQUIRE].includes(
        transition
    );
};

export const isCompleted = transition => {
    const txCompletedTransitions = [
        transitions.COMPLETE,
        transitions.REVIEW_1_BY_CUSTOMER,
        transitions.REVIEW_1_BY_PROVIDER,
        transitions.REVIEW_2_BY_CUSTOMER,
        transitions.REVIEW_2_BY_PROVIDER,
        transitions.EXPIRE_REVIEW_PERIOD,
        transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD,
        transitions.EXPIRE_PROVIDER_REVIEW_PERIOD,
    ];
    return txCompletedTransitions.includes(transition);
};

export const isRefunded = transition => {
    const txRefundedTransitions = [
        transitions.EXPIRE,
        transitions.DECLINE,
        transitions.CANCEL,
        transitions.CANCEL_PENDING_CONFIRMATION,
    ];
    return txRefundedTransitions.includes(transition);
};

export const isPendingPayment = lastTransition => {
    return lastTransition === transitions.MARK_PENDING_PAYMENT;
};

export const statesNeedingProviderAttention = [states.PENDING];